import {Col, Row} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";

export const Navbar = () => {
    const path = useLocation().pathname;
    return (
        <div className="bg-body-secondary shadow-sm py-1 mb-5 border-bottom border-3 ds-nav">
            <Row className="navbar mx-3 py-2 fs-4">
                <Col lg="6">
                    <Link to="/" className="link-light text-decoration-none fw-light fs-3">Dr. Michael Smith PsyD</Link>
                </Col>
                <Col>
                    <Link to="/" className={path === "/" ? "link-light text-decoration-none" : "link-secondary text-white-50 text-decoration-none bold"}>Home</Link>
                </Col>
                <Col>
                    <Link to="/specialty" className={path === "/specialty" ? "link-light text-decoration-none" : "link-secondary text-white-50 text-decoration-none bold"}>Specialty</Link>
                </Col>
                <Col>
                    <Link to="/forms" className={path === "/forms" ? "link-light text-decoration-none" : "link-secondary text-white-50 text-decoration-none bold"}>Forms</Link>
                </Col>
                <Col>
                    <Link to="/contact" className={path === "/contact" ? "link-light text-decoration-none" : "link-secondary text-white-50 text-decoration-none bold"}>Contact</Link>
                </Col>
            </Row>
        </div>
    )
}