import {Container} from "react-bootstrap";

export const Contact = () => {
    return (
        <div>
            <Container>
                <h2>Contact</h2>
                <br/>
                <h5>Confidential voicemail</h5>
                <a href="tel:303-424-8250">(303) 424-8250</a>
                <br/>
                <br/>
                <h5>Email</h5>
                <a href="mailto:contact@michaelsmithpsyd.com">contact@michaelsmithpsyd.com</a>
                <br/>
                <br/>
                <h5>Office hours</h5>
                <p>Monday through Thursday; Saturday</p>
                <br/>
                <h5>Map</h5>
                <a href="https://maps.app.goo.gl/d7vr9CYFjRt1Beoi9" target="_blank" rel="noreferrer">
                    5277 Manhattan Circle
                    <br/>
                    Boulder, Colorado 80303
                </a>
                <br/>
                Suite #110
                <br/>
                <br/>
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d195338.56834193235!2d-105.30328962187502!3d40.094031105436414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bed79b4b75915%3A0xa2f2defb40d42141!2s5277%20Manhattan%20Cir%2C%20Boulder%2C%20CO%2080303!5e0!3m2!1sen!2sus!4v1717868542499!5m2!1sen!2sus" width="600" height="450" referrerPolicy="no-referrer-when-downgrade" className="rounded-3 border border-1"></iframe>
            </Container>
        </div>
    )
}