import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Home} from "./pages/home";
import {Navbar} from "./navbar";
import {Specialty} from "./pages/specialty";
import {Forms} from "./pages/forms";
import {Contact} from "./pages/contact";

function App() {
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route exact path='/' element={<Home/>}></Route>
                <Route exact path='/specialty' element={<Specialty/>}></Route>
                <Route exact path='/forms' element={<Forms/>}></Route>
                <Route exact path='/contact' element={<Contact/>}></Route>
            </Routes>
            <br/>
            <br/>
            <br/>
        </Router>
    );
}

export default App;
