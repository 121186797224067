import {Card, Col, Container, Row} from "react-bootstrap";

export const Forms = () => {
    return (
        <div>
            <Container>
                <h2>Forms</h2>
                <br/>
                <Row className="justify-content-center">
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/disclosure-statement-for-psychotherapy.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Disclosure Statement for Psychotherapy</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/authorization-to-request-release-information.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Authorization to Request/Release Information</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/clients-application-for-service.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Client’s Application for Service</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row className="justify-content-center">
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/adult-clinical-information-form.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Adult Clinical Information Form</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/child-questionnaire.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Child Questionnaire</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/payment-contract-for-psychological-testing.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Payment Contract for Psychological Testing</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row className="justify-content-center">
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/client-contract-adolescents" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Client Contract and Statement of Financial Responsibility</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="rounded-5 ps-3 shadow-sm h-100 w-100">
                            <Card.Body>
                                <Card.Text className="pt-3">
                                    <a href="/forms/hppa-compliant-release-of-information.pdf" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#333333" className="bi bi-layout-text-sidebar-reverse" viewBox="0 0 16 16">
                                            <path d="M12.5 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm0 3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5zm.5 3.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z"/>
                                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2zM4 1v14H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2zm1 0h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5V1z"/>
                                        </svg>
                                    </a>
                                </Card.Text>
                                <Card.Title className="fs-5">Authorization for Use and Disclosure or Protected Health Information</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}