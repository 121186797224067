import {Accordion, Container} from "react-bootstrap";

export const Specialty = () => {
    return (
        <div>
            <Container>
                <h2>Areas of Specialty</h2>
                <br/>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Depression, Anxiety, Mood Disorders in Adults, Children and
                            Adolescents</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Depression and anxiety are normal responses to life. On one end of the spectrum
                                these feelings are fleeting, and pass without interfering with our daily lives.
                                But if these feelings become chronic or debilitating -- moods that do not lift
                                -- they can overwhelm our body and nervous system, and be the harbinger of a
                                mood disorder.
                                <br/>
                                <br/>
                                Each person has a distinct response to coping with worry, sadness, fear and
                                anger. Some of us feel more intensely than others, due to genetics, personality
                                and history. Our ability to rebound from stressors and cope with emotions can
                                also change over the years, thrown into disarray by such life shifts as becoming
                                a parent, aging, loss or trauma such changes as losing a job or moving.
                                <br/>
                                <br/>
                                At any point, these feelings can become overwhelming and throw off the internal
                                equilibrium that grounds us, interfering with the equanimity essential for a
                                successful, contented life.
                                <br/>
                                <br/>
                                Treatment of these issues entails a thorough assessment in order to understand
                                the nature, severity and history of the symptoms. I work to elucidate the
                                meaning each person gives to her or his feelings in reaction to life stressors.
                                For some people, their feelings or mood are so overwhelming that they are
                                immobilizing and they are unable to control their reactions. This may make it
                                impossible to separate one issue from another in order to work through these
                                feelings. In severe cases, this mindset may lead to impulsive, destructive or
                                life-threatening behaviors. While our therapy is ongoing, I will refer certain
                                patients to a medical provider to assess if medication is needed to address the
                                underlying biochemical imbalance that may impede the unraveling of the
                                psychological issues at play.
                                <br/>
                                <br/>
                                <b>Substance Abuse</b>
                                <br/>
                                <br/>
                                Often people suffering with depression, anxiety or other mood disorders also use
                                alcohol or drugs in an attempt to control or self-medicate their reactions to
                                disturbing emotions or feelings. Understanding how substance abuse interfaces
                                with anxiety or mood disorders, how that substance is used to manage
                                psychological distress, is also a critical piece of treatment. If the substance
                                abuse is of such severity that medical detox or medical treatment is required, I
                                will coordinate a local facility or hospital. I provide concurrent therapy while
                                the patient is enrolled in the substance abuse program.
                                <br/>
                                <br/>
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Maternal Depression, During Pregnancy and
                            Postpartum</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                For many women, the experience of having a baby and becoming a mother is much
                                different than what they expected it to be – both physical and psychological.
                                Motherhood is not just about joy, but also exhaustion, confusion, but also
                                coping with abrupt change in her relationship to her spouse, family, friends as
                                well as her own body. Added to that is also the isolation many a new mother
                                feels. The support of her partner is critical, but that person too many be
                                coping with this sea change of existence.
                                <br/>
                                <br/>
                                The hormonal and biochemical shifts that all women experience postpartum as well
                                as perhaps complications during pregnancy and birth, all contribute to the onset
                                of a mood disorder. Her personal history, previous emotional distress and the
                                quality of her relationships all play a part as well.
                                <br/>
                                <br/>
                                Through therapy, I help a mother explore her multifaceted reactions to
                                parenthood, and develop confidence in the evolving relationship with her child
                                and with her partner. This entails examining the breadth of issues that surface
                                during this major life transition, both personal and interpersonal. Her primary
                                connections to partner and family are crucial to weathering the psychological
                                and physical stresses of new motherhood.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Attention and Learning Disorders:
                            Assessment and Treatment</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                The ability to learn and process information has a significant impact on
                                self-concept throughout life. When a child is told to “just try harder” when he
                                or she knows that even with best effort peers are getting the same results with
                                less, can bring on helplessness and rebellious against those who are perceived
                                as tormentors.
                                <br/>
                                <br/>
                                One major cause of learning issues is Attention Deficit/ Hyperactivity Disorder.
                                But, learning issues can also be specific to math, reading or writing, or
                                related to Pervasive Developmental Disorders, speech and language disorders, and
                                more complex neurological disorders.
                                <br/>
                                <br/>
                                For this reason, a thorough assessment is essential to rule out these possible
                                issues, as well as to accurately assess how a child or adult processes
                                information. For anyone struggling to meet expectations — of teachers, parents,
                                even managers — the first step is to pinpoint the unique way that an individual
                                interprets and assimilates information from his or her environment. This
                                requires an exploration of the individual’s genetic, medical, neurological and
                                developmental history as well as a real-time assessment of how a person attends
                                to and inhibits his or her responses to visual and auditory stimuli.
                                <br/>
                                <br/>
                                From this, a profile can be developed that accurately describes a person’s
                                strengths and weaknesses in processing information, and can be used to yield a
                                diagnosis and an effective treatment regimen. There are significant neurological
                                differences between boys and girls, men and women, which can make diagnosis
                                difficult. This can be to blame for boys being over-diagnosed and girls often
                                left untreated until adulthood, if ever, which is why a thorough assessment is
                                so critical.
                                <br/>
                                <br/>
                                Mastering information requires a person to stay connected to the process of
                                learning. Individuals with Attention Deficit/Hyperactivity Disorder have great
                                difficulty staying focused on mundane details. The information generated by an
                                assessment can be used to tailor treatment that plays to a person’s strengths to
                                increase the likelihood of staying motivated and ultimately attaining mastery —
                                and learning successfully.
                                <br/>
                                <br/>
                                Mood and anxiety disorders can occur with attention and learning problems, and
                                will be exacerbated since anyone struggling at school or work would
                                understandably become worried and depressed. And likewise, learning problems can
                                result from anxiety and depression. This complexity underscores the importance
                                of a comprehensive assessment and accurate diagnosis in order to determine
                                effective treatment.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Couples Counseling</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                You might think a marriage is between two people, but in fact each person brings
                                the shadows of parents and a lifetime of partners to the marital relationship.
                                No relationship brings more primitive issues to the fore than marriage
                                <br/>
                                <br/>
                                In counseling, I help individuals to separate their issues from those of the
                                partner, and of the marriage. I help couples develop tools to navigate the
                                conscious and non-conscious reactions to one another, to better understand
                                beliefs, feelings and reactions to intimacy, This with time facilitates a purer
                                understanding of their partner, and the aspects of themselves their partner
                                reflects back to them..
                                <br/>
                                <br/>
                                An inherent dynamic in any relationship is the conflict that occurs when two
                                people realize they have a difference. Often it is difficult for individuals who
                                are intimate to stay connected when conflicts arise. When couples develop their
                                own strategies to negotiate conflict, as well as confidence in their own
                                emotional resilience – that marital issues won’t destroy them – this supports a
                                healthy, open and enduring relationship. To that end, couples need to understand
                                the overt and covert meaning of their communication, on a verbal and physical
                                level. Once an individual understands how he or she is triggered by specific
                                words and actions, and learn to communicate more effectively and react less
                                impulsively, the couple can begin to interrelate in more positive, productive
                                way.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Family Therapy</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Family or systems therapy is based on the idea that the whole is greater than
                                the sum of its parts; change in one member of the system can change the
                                functioning of the whole. It’s been my experience that families differ according
                                to the culture in which the parents and grandparents were raised, degree of
                                contact with extended family and affiliation with their community.
                                <br/>
                                <br/>
                                When establishing a working relationship with a family, I find it important to
                                understand its structure, to assess the kind of boundaries that exist between
                                the many groupings within the family -- the couple, the parent and child, and
                                among the children. I also look at the roles members take on to maintain the
                                equilibrium of the family; for example, one member may be the “problem,”
                                another, the “caretaker.” Helping the family to understand itself as a group,
                                and the individuals to see the constraints imposed by the unconsciously assigned
                                roles, can ultimately helps to free the family from habitual patterns often
                                enacted unintentionally, and form more healthy, dynamic bonds with one another.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Post-Divorce Adjustment</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                The often-quoted statistic says that about half of marriages end in divorce, that means
                                a good number of adults each year are faced with reimaging their lives after the end of
                                a marriage. The aftermath of divorces is always unsettling.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br/>
                <b>Other areas</b>
                <br/>
                <br/>
                <p className="ms-3">
                    Issues Related to Adoption, Attachment
                    <br/>
                    <br/>
                    Psychological Assessments, Evaluations
                    <br/>
                    <br/>
                    Blended Families
                    <br/>
                    <br/>
                    Professional Organizational Development
                </p>
            </Container>
        </div>
)
}