import {Col, Container, Row} from "react-bootstrap";

export const Home = () => {
    return (
        <div>
            <Container>
                <h2>Home</h2>
                <br/>
                <Row className="justify-content-center">
                    <Col lg="8">
                        <h4>We see only what we know.</h4>
                        <h6>— Goethe</h6>
                        <p className="pt-3">
                            I’ve been a psychotherapist for more than 30 years in the Denver/Boulder area, working with
                            adults, children and families. In my years of practice, I’ve witnessed a drastic shift in
                            health care; in behavioral health that trend has been toward short-term, one-size-fits-all
                            therapy. Research has shown that for some patients, this approach does more harm than good.
                            But, moreover, I believe this philosophy of treatment gives short shrift to the complexity
                            of the human spirit.
                            <br/>
                            <br/>
                            In my work, therapy evolves from the relationship between doctor and patient, and is about
                            collaboration and communication. My goal is to help others develop an understanding of the
                            forces that influence our behavior, and with that self-awareness, discover a better way of
                            being in the world and relating to those we love.
                        </p>
                        <p>
                            <br/>
                            <br/>
                            <b>About</b>
                            <br/>
                            <br/>
                            Michael D. Smith received his doctorate in counseling psychology from the University of
                            Northern Colorado. He completed at internship at Primary Children’s Medical Center in at the
                            University of Utah Medical School in Salt Lake City. A native of Cincinnati, Dr. Smith
                            received his undergraduate degree from the University of North Carolina, Greensboro,
                            majoring in religious studies.
                            <br/>
                            <br/>
                            He conducted his dissertation research on postpartum depression, specifically on how the
                            marital relationship, family/community support and personal history contribute to the onset
                            of maternal depression. His findings were presented at the annual national convention of the
                            American Psychological Association (APA).
                            <br/>
                            <br/>
                            Dr. Smith has been a psychotherapist in private practice for more than 15 years. He has been
                            a staff therapist for area crisis teams, hospitals and mental health facilities including
                            Boulder Community Mental Health, Boulder Child, Adolescent and Family Services and St.
                            Anthony’s In-Patient Psychiatric Unit. He also was the lead technician at several
                            electroencephalograph (EEG) labs
                            <span onDoubleClick={() => {
                                alert("Only in Ohio 💀\n\nEaster egg by the website designer.")
                            }}>
                                &nbsp;in Ohio&nbsp;
                            </span>
                            and Colorado.
                            <br/>
                            <br/>
                            He collaborates with local OB/GYN, pediatric and family practice groups to integrate medical
                            and psychological care. His experience includes a previous career in theater and dance,
                            which also informs his approach – addressing the interplay between issues of body, mind and
                            human experience.
                        </p>
                        <p>
                            <br/>
                            <br/>
                            <b>My Philosphy</b>
                            <br/>
                            <br/>
                            As a psychologist, I consider listening to be the primary way I help my patients, whether
                            they be individuals, couples, children or families. I’ve learned that if my patients feel
                            they are heard and respected, they develop confidence in the therapeutic relationship and
                            real progress is made.
                            <br/>
                            <br/>
                            To understand the issues that bring each person to therapy, I consider it essential to
                            listen to their narrative — how they tell the story of their life. A scientifically accurate
                            psychological assessment — critical to developing a plan for treatment — is useful only if
                            it reflects the particulars of the person it is intended to help. Despite the introduction
                            of technology into the field of human behavior, psychology is still about the idiosyncratic
                            makeup of the individual. The doctor and patient relationship provides the foundation of
                            trust. For the alchemy of therapy to occur, each patient needs to first feel heard, accepted
                            and understood.
                        </p>
                    </Col>
                    <Col lg="4">
                        <img src="/img/michaelBW.jpg" alt="Dr. Michael Smith"
                             className="img-fluid rounded float-start m-5 rounded-3 border border-1" width={"400px"}/>
                        <img src="/img/office.png" alt="Dr. Michael Smith"
                             className="img-fluid rounded float-start m-5 rounded-3 border border-1" width={"400px"}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}